'use client';
import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import Button from '@gds/Button/Button';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtomValue } from 'jotai';
import Link from 'next/link';

import { PromotionCta } from 'Components/Promotions/PromotionsHomePage/Promotions.entity';

import { generatePromotionsHomePageTracking } from './PromotionsHomePage.tracking';

import styles from './PromotionsHomePage.module.css';

interface PromotionHomePageCtaProps {
  primaryCta?: PromotionCta[];
  secondaryCta?: PromotionCta[];
  index: number;
}

export const PromotionsHomePageCta = ({
  primaryCta,
  secondaryCta,
  index,
}: PromotionHomePageCtaProps) => {
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const formState = useAtomValue(formStateAtom);

  const handleClick = (ctaWording: string, ctaUrl: string) => {
    const { event, context } = generatePromotionsHomePageTracking({
      searchParams,
      userId,
      formState,
      ctaType: 'button',
      ctaWording,
      ctaUrl,
    });

    trackCustomEvent({ event, context });
  };

  return (
    <>
      {secondaryCta && secondaryCta.length > 0 && (
        <Button
          Component={Link}
          href={secondaryCta[0].link}
          className={styles.secondaryCta}
          variant="link"
          dataTestId={`secondaryCta${index}`}
          onClick={() => handleClick(secondaryCta[0].label, secondaryCta[0].link)}
        >
          {secondaryCta[0].label}
        </Button>
      )}
      {primaryCta && primaryCta.length > 0 && (
        <Button
          Component={Link}
          href={primaryCta[0].link}
          className={styles.primaryCta}
          aria-label="promo link"
          variant="contained"
          dataTestId={`primaryCta${index}`}
          onClick={() => handleClick(primaryCta[0].label, primaryCta[0].link)}
        >
          {primaryCta[0].label}
        </Button>
      )}
    </>
  );
};

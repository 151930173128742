import { Locale } from '@schema-markup/types';

export const MAX_STARS_COUNT = 5;

export const getBusinessUnitId = (locale: Locale) =>
  locale === 'de' ? '5c65db740526720001ee299b' : '5d526139dee7ff000199ccba';

export const getCaroTemplateId = (locale: Locale) =>
  locale === 'de' ? '54ad5defc6454f065c28af8b' : '53aa8912dec7e10d38f59f36';

export const getTemplateId = (isFourStars = true) => {
  return isFourStars ? '5406e65db0d04a09e042d5fc' : '54ad5defc6454f065c28af8b';
};

export const getMappedLocale = (locale: Locale) => (locale === 'de' ? 'de-DE' : 'en-GB');

export const getTrustPilotUrl = (locale: Locale) =>
  locale === 'de'
    ? 'https://de.trustpilot.com/review/hey.car?utm_medium=trustbox&utm_source=Slider'
    : 'https://uk.trustpilot.com/review/heycar.co.uk?utm_medium=trustbox&utm_source=Slider';

import { RichText } from '@page-builder/Components/RichText/RichText';

import styles from './HeroCampaignBanner.module.css';

interface Props {
  content: {
    color: string;
    shortText: string;
    fullText: string;
  };
}

export const HeroCampaignBannerModal = ({ content: { shortText, fullText, color } }: Props) => {
  return (
    <div className={styles.modalWrapper} data-color={color}>
      <div className={styles.heading}>
        <h3>{shortText}</h3>
      </div>
      <div className={styles.content}>
        <RichText richText={fullText} />
      </div>
    </div>
  );
};

'use client';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import Button from '@gds/Button/Button';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtomValue } from 'jotai';
import Link from 'next/link';

import { generateLandingPageBlockTracking } from './LandingPageBlock.tracking';

type LandingPageBlockCtaProps = {
  ctaText: string;
  ctaUrl: string;
  pageSlug: string;
  className: string;
};

export const LandingPageBlockCta = ({
  ctaText,
  ctaUrl,
  pageSlug,
  className,
}: LandingPageBlockCtaProps) => {
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const formState = useAtomValue(formStateAtom);

  // add pageSlugs to newTabSlugs if you want to open the link in a new tab
  const slugsForNewTabs = ['abonnements'];
  const isPageInNewTab = slugsForNewTabs.includes(pageSlug);

  const handleClick = (ctaWording: string, ctaUrl: string) => {
    const { event, context } = generateLandingPageBlockTracking({
      searchParams,
      userId,
      formState,
      ctaType: 'button',
      ctaWording,
      ctaUrl,
    });

    trackCustomEvent({ event, context });
  };
  return (
    <Button
      Component={Link}
      href={ctaUrl}
      variant="contained"
      className={className}
      target={isPageInNewTab ? '_blank' : '_self'}
      onClick={() => handleClick(ctaText, ctaUrl)}
    >
      {ctaText}
    </Button>
  );
};

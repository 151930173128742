'use client';

import Button from '@gds/Button/Button';
import { Typography } from '@gds/Typography/Typography';
import Link from 'next/link';

import { FloatingCtaProps } from './FloatingCta.entity';

import styles from './FloatingCta.module.css';

const FloatingCta = ({
  body,
  primaryCtaText,
  primaryCtaSlug,
  primaryCtaStyle,
  secondaryCtaText,
  secondaryCtaSlug,
  ctaAlignment,
}: FloatingCtaProps) => {
  return (
    <div className={styles.wrapper}>
      {body && (
        <Typography variant="body3" className={styles.bodyText}>
          {body}
        </Typography>
      )}
      <div
        className={`${styles.ctas} ${ctaAlignment[0]?.codename === 'central' && styles.central}`}
      >
        {primaryCtaText && primaryCtaSlug && (
          <Link href={primaryCtaSlug} passHref legacyBehavior prefetch={false}>
            <Button
              variant={primaryCtaStyle[0]?.codename}
              className={`${styles.button} ${styles.primary}`}
              data-test-id="ctaButton"
            >
              {primaryCtaText}
            </Button>
          </Link>
        )}

        {secondaryCtaText && secondaryCtaSlug && (
          <Link href={secondaryCtaSlug} passHref legacyBehavior prefetch={false}>
            <Button
              variant={primaryCtaStyle[0]?.codename === 'contained' ? 'outlined' : 'contained'}
              className={styles.button}
              data-test-id="ctaButton"
            >
              {secondaryCtaText}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default FloatingCta;

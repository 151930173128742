'use client';
import { ReactElement } from 'react';

import { ChevronLeft } from '@gds/Icons/Paths/ChevronLeft';
import { ChevronRight } from '@gds/Icons/Paths/ChevronRight';

import styles from './CompanyReviews.module.css';
import { useCompanyReviews } from './useCompanyReviews';
import { ARIA_LABELS } from './CompanyReview.constants';
import { Locale } from '@core/Entities/Locale/Locale.entity';

export const CompanyReviews = ({
  locale,
  children,
}: {
  locale: Locale;
  children: ReactElement[];
}) => {
  const { containerRef, handleMove, enablePrevNavButton, enableNextNavButton, transX } =
    useCompanyReviews(children.length);
  const labels = ARIA_LABELS[locale];

  return (
    <div
      className={styles.carousel}
      role="group"
      aria-roledescription="carousel"
      aria-label={labels.carousel}
    >
      <button
        className={`${styles.arrow} ${styles.left} ${enablePrevNavButton ? styles.active : ''}`}
        onClick={() => handleMove('left')}
      >
        <ChevronLeft />
      </button>
      <div className={styles.container} ref={containerRef}>
        <div
          className={styles.reel}
          style={{ transform: `translate(${transX}px, 0)` }}
          aria-atomic="false"
          aria-live="polite"
        >
          {children}
        </div>
      </div>
      <button
        className={`${styles.arrow} ${styles.right} ${enableNextNavButton() ? styles.active : ''}`}
        onClick={() => handleMove('right')}
      >
        <ChevronRight />
      </button>
    </div>
  );
};

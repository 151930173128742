import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { SearchParamsType } from '@core/Atoms/SearchParams/SearchParams.atom';
import { callStartToEvent } from '@tracking/Schemas/CallStart/CallStartToEvent';
import { ctaClickedToEvent } from '@tracking/Schemas/CtaClicked/CtaClickedToEvent';
import { marketingToEvent } from '@tracking/Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from '@tracking/Schemas/UserContext/UserContextToEvent';

interface CtaProps {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  ctaType: string;
  ctaWording: string;
  ctaUrl: string;
}

export function generateFloatingLeasingCtaTracking({
  searchParams,
  userId,
  formState,
  ctaType,
  ctaWording,
  ctaUrl,
}: CtaProps) {
  const marketingDataObj = {
    marketingValues: { ...(searchParams ?? {}) },
  };

  const userData = {
    userId,
    values: { ...formState },
  };
  const ctaEventObj = {
    ctaType,
    ctaWording,
    targetUrl: ctaUrl,
  };

  // event actions
  const ctaEvent = ctaClickedToEvent(ctaEventObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const marketingEvent = marketingToEvent(marketingDataObj);

  const context = [userEvent, marketingEvent];

  return { event: ctaEvent, context };
}

interface LinkProps {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  listingId: string;
  leadType: string;
}

export function generateFloatingLeasingLinkTracking({
  searchParams,
  userId,
  formState,
  listingId,
  leadType,
}: LinkProps) {
  const marketingDataObj = {
    marketingValues: { ...(searchParams ?? {}) },
  };

  const userData = {
    userId,
    values: { ...formState },
  };

  const leadStartObj = {
    listingId,
    leadType,
  };

  // event actions
  const callStartEvent = callStartToEvent(leadStartObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const marketingEvent = marketingToEvent(marketingDataObj);

  const context = [userEvent, marketingEvent];

  return { event: callStartEvent, context };
}

'use client';

import { useEffect, useRef } from 'react';

import Script from 'next/script';

import { Typography } from '@gds/Typography/Typography';
import { Locale } from 'libs/schema-markup/src/types';

import styles from './TrustpilotWidgetSlider.module.css';

import {
  getBusinessUnitId,
  getCaroTemplateId,
  getMappedLocale,
  getTrustPilotUrl,
} from '../TrustpilotWidget.constants';
import { trustpilotMessages } from '../TrustpilotWidget.messages';

interface TrustpilotWidgetSliderProps {
  strings: Pick<Record<keyof typeof trustpilotMessages, string>, 'trustpilotWidgetCustomer'>;
  locale: Locale;
}

export const TrustpilotWidgetSlider = ({ strings, locale }: TrustpilotWidgetSliderProps) => {
  const ref = useRef(null);
  const businessUnitId = getBusinessUnitId(locale);
  const templateId = getCaroTemplateId(locale);
  const language = getMappedLocale(locale);

  let languages = 'en';
  if (locale === 'de') {
    languages = locale;
  }

  const trustPilotUrl = getTrustPilotUrl(locale);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Trustpilot && locale !== 'fr') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  if (locale === 'fr') return null;

  return (
    <>
      <Script
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="afterInteractive"
      />

      <div className={styles.tpSlider} data-test-id="trustpilot-widget-slider">
        <Typography variant="h2">{strings.trustpilotWidgetCustomer}</Typography>
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale={language}
          data-template-id={templateId}
          data-businessunit-id={businessUnitId}
          data-style-width="100%"
          data-theme="light"
          data-stars="5"
          data-review-languages={languages}
        >
          <a href={trustPilotUrl} target="_blank" rel="noopener" aria-label="trust widget slider">
            Trustpilot
          </a>
        </div>
      </div>
    </>
  );
};

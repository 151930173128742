'use client';

import React, { FC } from 'react';

import { TabsProps } from './Tabs.types';

import styles from './Styles/Default.module.css';

export const Tabs: FC<TabsProps & { idPrefix: string }> = React.forwardRef<
  HTMLDivElement,
  TabsProps & { idPrefix: string }
>(
  (
    { className, activeIndex, buttons, dataTestId, onChange, tabContents, idPrefix, ...restProps },
    ref,
  ) => {
    const keyboardOpen = (
      e: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>,
      i: number,
    ) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        onChange(i);
      }
    };

    return (
      <div
        className={`${styles.wrapper} ${className}`}
        data-test-id={dataTestId}
        ref={ref}
        {...restProps}
      >
        <div className={styles.btnWrapper} role="tablist">
          {buttons.map((btn, i) => {
            const isActive = activeIndex === i;

            return (
              <button
                aria-controls={`panel-${i}-${idPrefix}`}
                aria-selected={isActive}
                aria-label={btn.label}
                className={`typography-subheading-3 ${styles.btn}`}
                data-test-id={btn.dataTestId}
                disabled={btn.isDisabled}
                key={btn.label}
                onClick={() => {
                  if (!isActive) onChange(i);
                }}
                onKeyDown={e => {
                  if (!isActive) keyboardOpen(e, i);
                }}
                role="tab"
              >
                {btn.icon}
                {btn.label}
              </button>
            );
          })}
        </div>

        {tabContents.map((content, i) => {
          const isActive = activeIndex === i;
          const btn = buttons[i];
          return (
            <div
              aria-label={`${btn?.label}-panel`}
              className={`${styles.contentWrapper} ${isActive ? 'selected-panel' : ''}`}
              hidden={!isActive}
              id={`panel-${i}-${idPrefix}`}
              key={`panel-${i}-${idPrefix}`}
              role="tabpanel"
            >
              {content}
            </div>
          );
        })}
      </div>
    );
  },
);

Tabs.displayName = 'Tabs';

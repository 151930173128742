'use client';

import { FC, ReactElement, useEffect, useRef, useState } from 'react';

import { ChevronLeft } from '@gds/Icons/Paths/ChevronLeft';
import { ChevronRight } from '@gds/Icons/Paths/ChevronRight';
import { FullWidth } from '@page-builder/Components/FullWidth/FullWidth';

import styles from './Scroller.module.css';

type ScrollerProps = {
  scrollItemClass: string;
  children: ReactElement | ReactElement[];
  threshold?: number;
  currentVisibleItemIndex?: number;
  contained?: boolean;
  showDots?: boolean;
};

export const Scroller: FC<ScrollerProps> = ({
  children,
  threshold = 1,
  scrollItemClass,
  contained,
  showDots,
}) => {
  const $scrollBox = useRef<HTMLDivElement>(null);
  const $scrollBoxDots = useRef<HTMLDivElement>(null);
  const [isFirstVisible, setIsFirstVisible] = useState(true);
  const [isLastVisible, setIsLastVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      const scrollItems = $scrollBox.current?.querySelectorAll(`.${scrollItemClass}`);
      if (!scrollItems) return;

      const observers = Array.from(scrollItems, (scrollItem, i) => {
        // @ts-expect-error - style is not on Element type
        scrollItem.style['scroll-snap-align'] = 'start';
        if (typeof IntersectionObserver === 'undefined') return;

        const observer = new IntersectionObserver(
          mutations =>
            mutations.forEach(mutation => {
              if (i === 0) setIsFirstVisible(mutation.isIntersecting);
              if (i === scrollItems.length - 1) setIsLastVisible(mutation.isIntersecting);
              if (showDots) {
                const scrollBoxDots = $scrollBoxDots.current?.querySelector(
                  `.scrollbox-dot:nth-child(${i + 1})`,
                );
                scrollBoxDots?.setAttribute('data-is-active', `${mutation.isIntersecting}`);
              }
            }),
          { root: $scrollBox.current, threshold },
        );

        observer.observe(scrollItem);

        return observer;
      });

      return () => observers?.forEach?.(observer => observer?.disconnect());
    }
  }, [isMounted]);

  const scrollBy = (amount: number, isActive: boolean) => () => {
    if (isActive) {
      const $scrollItem = $scrollBox.current?.querySelector(`.${scrollItemClass}`);
      if (!$scrollItem) return;

      const itemSize = $scrollItem?.clientWidth || 0;
      $scrollBox.current?.scrollBy({ left: itemSize * amount, behavior: 'smooth' });
    }
  };

  const renderScrollDots = () => {
    const scrollItems = $scrollBox.current?.querySelectorAll(`.${scrollItemClass}`);
    if (!scrollItems) return;

    return (
      <div className={`${styles.dots} intro-scrollbox-dots`} ref={$scrollBoxDots}>
        {Array.from(scrollItems, (_, i) => (
          <span key={i} className={`${styles.dot} scrollbox-dot`} />
        ))}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      {contained ? (
        <div className={`${styles.scrollBox} reviews-scrollbox intro-scrollbox`} ref={$scrollBox}>
          {children}
        </div>
      ) : (
        <FullWidth>
          <div className={`${styles.scrollBox} reviews-scrollbox intro-scrollbox`} ref={$scrollBox}>
            <div className={styles.scrollItems}>{children}</div>
          </div>
        </FullWidth>
      )}
      <div className={`${styles.nav} reviews-nav intro-scrollbox-nav`}>
        <ChevronLeft onClick={scrollBy(-1, !isFirstVisible)} data-is-active={!isFirstVisible} />
        <ChevronRight onClick={scrollBy(1, !isLastVisible)} data-is-active={!isLastVisible} />
      </div>
      {showDots && renderScrollDots()}
    </div>
  );
};

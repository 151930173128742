/* eslint-disable @typescript-eslint/naming-convention */
'use server';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { VehicleValuationData } from '@core/Entities/Vehicle/Valuation.entity';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';

import { TRADE_IN_URLS } from './TradeIn.constants';
import { TradeInResponse } from './TradeIn.entity';

export async function tradeInAction(
  reg: string,
  mileage: string,
  locale: Locale,
): Promise<TradeInResponse> {
  const url = TRADE_IN_URLS[locale] || undefined;

  if (!url) return { error: 'NOT AVAILABLE', data: undefined };

  try {
    const response = await fetchJson<VehicleValuationData>(
      `${url}?registration=${reg.trim()}&mileage=${mileage.trim()}`,
    );
    return {
      data: response,
      error: undefined,
    };
  } catch (error: any) {
    return { error: 'FAILED', data: undefined };
  }
}

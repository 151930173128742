import { CtaClickedEvent } from './CtaClickedEvent';
import { ctaClickedSchema } from './CtaClickedSchema';

export const ctaClickedToEvent = ({
  ctaType,
  ctaWording,
  targetUrl,
  ctaLocation,
}: {
  ctaType: string;
  ctaWording: string;
  targetUrl: string;
  ctaLocation?: string;
}): CtaClickedEvent => {
  const event: CtaClickedEvent = {
    schema: ctaClickedSchema.ref,
    data: {
      cta_type: ctaType,
      cta_wording: ctaWording,
      target_url: targetUrl,
      cta_location: ctaLocation,
    },
  };

  return event;
};

'use client';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import Button from '@gds/Button/Button';
import { Typography } from '@gds/Typography/Typography';

import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtomValue } from 'jotai';
import Link from 'next/link';

import { FloatingCtaLeasingProps } from './FloatingLeasingCta.entity';

import {
  generateFloatingLeasingCtaTracking,
  generateFloatingLeasingLinkTracking,
} from './FloatingLeasingCta.tracking';

import styles from '../FloatingCta/FloatingCta.module.css';

const FloatingLeasingCta = ({
  bodyText,
  primaryCtaText,
  primaryCtaSlug,
  primaryCtaStyle,
  callButtonCtaText,
  callButtonCtaSlug,
  ctaAlignment,
}: FloatingCtaLeasingProps) => {
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const formState = useAtomValue(formStateAtom);

  const handleClick = (ctaUrl: string) => {
    const { event, context } = generateFloatingLeasingCtaTracking({
      searchParams,
      userId,
      formState,
      ctaType: 'link',
      ctaWording: 'Get in touch for leasing',
      ctaUrl,
    });

    trackCustomEvent({ event, context });
  };

  const handleLinkClick = () => {
    const { event, context } = generateFloatingLeasingLinkTracking({
      searchParams,
      userId,
      formState,
      listingId: 'uk_leasing_stock',
      leadType: 'leasing',
    });

    trackCustomEvent({ event, context });

    window.location.href = `tel:${callButtonCtaText}`;
  };

  return (
    <div className={styles.wrapper}>
      {bodyText && (
        <Typography variant="body3" className={styles.bodyText}>
          {bodyText}
        </Typography>
      )}
      <div
        className={`${styles.ctas} ${ctaAlignment[0]?.codename === 'central' && styles.central}`}
      >
        {primaryCtaText && primaryCtaSlug && (
          <Button
            Component={Link}
            href={primaryCtaSlug}
            variant={primaryCtaStyle[0]?.codename}
            className={`${styles.button} ${styles.primary}`}
            data-test-id="ctaButton"
            onClick={() => handleClick(primaryCtaSlug)}
          >
            {primaryCtaText}
          </Button>
        )}

        {callButtonCtaText && callButtonCtaSlug && (
          <Button
            Component={Link}
            href={callButtonCtaSlug}
            variant="outlined"
            className={`${styles.button} ${styles.primary}`}
            data-test-id="ctaButtonCall"
            onClick={() => handleLinkClick()}
          >
            {callButtonCtaText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FloatingLeasingCta;

'use client';
import { ReactNode, useState } from 'react';

import { Tabs } from '@gds/Tabs/Tabs';

import styles from './SearchFormTabs.module.css';

interface SearchFormTabsProps {
  tabContents: ReactNode[];
  strings: Record<string, string>;
  idPrefix: string;
}

const SearchFormTabs = ({ tabContents, strings, idPrefix }: SearchFormTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (i: number) => {
    setActiveTab(i);
  };

  const buttons = [
    {
      label: strings.findMyCar,
    },
    {
      label: strings.valueMyCar,
    },
  ];

  return (
    <Tabs
      idPrefix={idPrefix}
      activeIndex={activeTab}
      buttons={buttons}
      className={styles.wrapper}
      onChange={i => handleTabChange(i)}
      tabContents={tabContents}
    />
  );
};

export default SearchFormTabs;

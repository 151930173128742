import { de } from 'date-fns/locale/de';
import { fr } from 'date-fns/locale/fr';
import { uk } from 'date-fns/locale/uk';

export const DATE_LOCALES = {
  de,
  fr,
  uk,
};

export const ARIA_LABELS = {
  de: {
    carousel: 'heycar-Bewertungen von Google',
    review: '{SCORE}-Sterne-Bewertung von {REVIEWER}',
  },
  fr: {
    carousel: 'avis heycar de Google',
    review: 'Avis {SCORE} étoiles par {REVIEWER}',
  },
  uk: {
    carousel: 'heycar reviews from Google',
    review: '{SCORE} star review by {REVIEWER}',
  },
};

export const ITEM_WIDTH = 208;
export const ITEM_PP_THRESHOLD = 16;
